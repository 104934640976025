import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ComingSoon.css'; // Custom styles including animations

const ComingSoon = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  // Countdown logic
  useEffect(() => {
    const countdownDate = new Date('2024-09-30T00:00:00').getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendEmail = (e) => {
    e.preventDefault();

    axios.post('http://localhost:5000/api/emails', { email })
      .then((response) => {
        console.log(response.data);
        setEmailSent(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setEmailSent(false);
      });
  };

  return (
    <div className="coming-soon">
      <div className="background-animation"></div>
      <div className="title">Pritam Nayak Couture</div>
      <h1>We Are Coming Soon!</h1>
      <p>Stay tuned for something amazing. We're working hard to bring you something great.</p>

      {/* Countdown Timer */}
      <div className="countdown">
        <div className="countdown-item">
          <span>{timeLeft.days}</span>
          <p>Days</p>
        </div>
        <div className="countdown-item">
          <span>{timeLeft.hours}</span>
          <p>Hours</p>
        </div>
        <div className="countdown-item">
          <span>{timeLeft.minutes}</span>
          <p>Minutes</p>
        </div>
        <div className="countdown-item">
          <span>{timeLeft.seconds}</span>
          <p>Seconds</p>
        </div>
      </div>

      {/* Email Subscription */}
      <form onSubmit={handleSendEmail} className="email-form">
        <input
          type="email"
          name="user_email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit" className="send-email-button">Send</button>
        {emailSent && <p className="email-success">Email sent successfully!</p>}
        {!emailSent && email && <p className="email-failure">Failed to send email. Please try again.</p>}
      </form>

      {/* Social Media Links */}
      <div className="social-icons">
        <a href="https://www.facebook.com" target="_blank" rel="noreferrer">Facebook</a>
        <a href="https://www.instagram.com" target="_blank" rel="noreferrer">Instagram</a>
        <a href="https://www.twitter.com" target="_blank" rel="noreferrer">Twitter</a>
      </div>
    </div>
  );
};

export default ComingSoon;
